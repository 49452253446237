//**************************************************//
//* COLORS                                         *//
//**************************************************//
$dark-navy: #0e2248;
$navy: #17335d;
$active-blue: #edf8fc;
$motili-light-blue: #57c1e9;
$blue: #05a6e6;
$required-blue: #3f82a5;
$green: #15b039;
$light-green: #edfaea;
$less-gnarly-green: #5cb85c;
$white: #ffffff;
$black: #000000;
$orange: #fc6d17;
$red: #e63232;
$alert-background: #fceaea;
$warning-background: #fffcee;
$yellow: #fcb817;
$dark-gray: #3b3b3b;
$less-dark-gray: #808080;
$medium-gray: #818181;
$medium-dark-gray: #aaaaaa;
$medium-light-gray: #d8d8d8;
$light-gray: #e9e9e9;
$super-light-gray: #f5f5f5;
$background-gray: #f5f5f5;
$background-white: #ffffff;
$border-gray: #cccccc;
$dark-charcoal: #333;
$light-red: #faeaea;
$strong-blue: #2d5ecd;

//**************************************************//
//* BRAND COLORS                                   *//
//**************************************************//
$brand-primary: $motili-light-blue !default;
$brand-success: $green !default;
$brand-info: #5bc0de !default; // not a Motili color
$brand-warning: $orange !default;
$brand-danger: $red !default;
$brand-default: $light-gray !default;
